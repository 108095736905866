import { BodyType } from "../../../src/lib/api-client/api-client";
import {
  authGoogleLoginCreate,
  authLoginCreate,
  useAuthSendEmailRestorePasswordCreate,
} from "../../../src/shared/api/endpoints/auth/auth";
import {
  LoginGoogle,
  LoginRequest,
  SendEmailRestorePasswordRequest,
} from "../../../src/shared/domain/models";
import { AuthAdapter } from "../domain";

class AuthApiAdapter implements AuthAdapter {
  login = (loginRequest: BodyType<LoginRequest>) => {
    return authLoginCreate(loginRequest);
  };
  signInWithGoogle = (token: BodyType<LoginGoogle>) => {
    return authGoogleLoginCreate(token);
  };
  sendEmailRestorePassword = (data?: SendEmailRestorePasswordRequest) => {
    const { mutateAsync } = useAuthSendEmailRestorePasswordCreate();

    return mutateAsync;
    // return { mutate: mutate as typeof mutate };
  };
}

export { AuthApiAdapter };
