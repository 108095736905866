import * as z from "zod";
import { imageSchema, linkSchema } from "../../../../src/shared/domain/types";

/**
 * Schemas
 */

// Hero
export const heroItemSchema = z.object({
  icon: imageSchema,
  label: z.string(),
});
export const heroSchema = z.object({
  title: z.string(),
  description: z.string(),
  link: linkSchema,
  backgroundImg: imageSchema,
  items: z.array(heroItemSchema),
});

// Video
export const videoSchema = z.object({
  title: z.string().optional(),
  link: linkSchema,
  poster: z.string(),
});

// Scrolly Telling
export const scrollyTellingSectionSchema = z.object({
  title: z.string(),
  description: z.string(),
  image: imageSchema,
});
export const scrollyTellingSchema = z.object({
  title: z.string().optional(),
  sections: z.array(scrollyTellingSectionSchema),
});

// Stats
export const statsItemSchema = z.object({
  label: z.string(),
  value: z.string(),
});
export const statsSchema = z.object({
  title: z.string(),
  description: z.string(),
  items: z.array(statsItemSchema),
  link: linkSchema,
});

// Clients
export const clientsSchema = z.object({
  title: z.string().optional(),
  items: z.array(imageSchema),
});

// Testimonial
export const testimonialItemSchema = z.object({
  id: z.string(),
  image: imageSchema,
  description: z.string(),
  name: z.string(),
  job: z.string(),
});
export const testimonialSchema = z.object({
  title: z.string().optional(),
  items: z.array(testimonialItemSchema),
});

// Keywords
export const keywordsSchema = z.object({
  title: z.string(),
  description: z.string(),
  items: z.array(z.string()),
});

// PolifonIA
export const publicListeningAvatarSchema = z.object({
  image: imageSchema,
  label: z.string(),
});
export const publicListeningSpeakerSchema = z.object({
  image: imageSchema,
  description: z.string(),
});
export const publicListeningSchema = z.object({
  title: z.string(),
  description: z.string(),
  speaker: publicListeningSpeakerSchema,
  avatars: z.array(publicListeningAvatarSchema),
});

// Banner
export const bannerItemSchema = z.object({
  image: imageSchema,
  label: z.string(),
});
export const bannerSchema = z.object({
  title: z.string(),
  description: z.string(),
  link: linkSchema,
  items: z.array(bannerItemSchema),
});

// Home
export const homeSchema = z.object({
  hero: heroSchema,
  video: videoSchema,
  scrollyTelling: scrollyTellingSchema,
  stats: statsSchema,
  clients: clientsSchema,
  testimonial: testimonialSchema,
  keywords: keywordsSchema,
  publicListening: publicListeningSchema,
  banner: bannerSchema,
});

/**
 * Types
 */

export type HeroEntity = z.infer<typeof heroSchema>;

export type VideoEntity = z.infer<typeof videoSchema>;

export type ScrollyTellingEntity = z.infer<typeof scrollyTellingSchema>;

export type StatsEntity = z.infer<typeof statsSchema>;

export type ClientsEntity = z.infer<typeof clientsSchema>;

export type TestimonialEntity = z.infer<typeof testimonialSchema>;

export type KeywordsEntity = z.infer<typeof keywordsSchema>;

export type PublicListeningEntity = z.infer<typeof publicListeningSchema>;

export type BannerEntity = z.infer<typeof bannerSchema>;

export type HomeEntity = z.infer<typeof homeSchema>;
