"use client";
import "./keywords.css";
import { motion as m, Variants } from "framer-motion";
import { useLayoutEffect, useRef } from "react";

import { useLandingContentThirdGetSuspense as useLandingContentThirdGet } from "architecture/src/shared/api/endpoints/landing/landing";

import type { HomeEntity } from "architecture/features/home/domain/entities";

import { Typography } from "@/components/ui/data-display/typography";
import { Stack } from "@/components/ui/layout/stack";

import { keywords } from "./data/index";

import { useKeywordsStore } from "./store";

const useKeywords = () => {
  const ref = useRef<HTMLDivElement>(null);
  const { setRefSection } = useKeywordsStore();

  useLayoutEffect(() => {
    setRefSection(ref);
  }, [ref, setRefSection]);

  return {
    ref,
  };
};

type KeyWordsProps = HomeEntity["keywords"];

const variants: Variants = {
  initial: {
    opacity: 0,
    scale: 0.95,
    x: "-20%",
  },
  animate: {
    opacity: 1,
    scale: 1,
    x: "0%",
    rotateY: 0,
    transition: {
      type: "spring",
      stiffness: 80,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    x: "20%",
  },
};

export const KeyWords: React.FC<KeyWordsProps> = ({ title, description }) => {
  const { ref } = useKeywords();
  const { data: content } = useLandingContentThirdGet();

  return (
    <>
      <m.section ref={ref} className="my-10">
        <div className="container flex flex-col mx-auto gap-6 overflow-hidden items-center lg:flex-row md:px-16 md:justify-between">
          <div className="relative md:m-0 w-full  lg:w-7/12 h-full minh2xs min-h-[251px] max-w-[470px]  md:max-w-[477px] lg:max-w-[878px]  mx-auto  lg:min-h-[371px] overflow-hidden  ">
            {keywords.map((item, index) => (
              <m.div
                whileInView={{
                  opacity: 1,
                  scale: 1,
                  rotateY: 0,
                  transition: {
                    type: "spring",
                    stiffness: 80,
                    delay: index * 0.08,
                  },
                }}
                initial={{
                  opacity: 0,
                  scale: 0,
                }}
                exit={{ opacity: 0, scale: 0.2 }}
                key={`${item}-${index}`}
                className="absolute whitespace-nowrap"
                style={{
                  top: item.top,
                  left: item.left,
                  opacity: item.opacity,
                  fontSize: item.size,
                  fontWeight: item.weight,
                  color: item.color,
                }}
              >
                <p style={{ transform: `rotate(${item.rotate})` }}>
                  {item.text}
                </p>
              </m.div>
            ))}
          </div>

          {/* Card */}

          <m.div variants={variants} initial="initial" whileInView="animate">
            <Stack className="h-full border-2 border-primary/50 rounded-[40px] w-full max-w-[375px]">
              <Stack
                spacing="md"
                className="relative px-4 py-4 md:py-20 text-center w-full md:gap-1"
              >
                <Typography variant="h3" className="text-primary">
                  {content.title}
                </Typography>

                <Typography className="px-8" variant="h5" component="p">
                  {content.description}
                </Typography>
              </Stack>
            </Stack>
          </m.div>
        </div>
      </m.section>
    </>
  );
};
