import Image from "next/image";

import { AspectRatio } from "@/components/ui/surfaces/aspect-ratio";
import DapperIsoLogo from "public/static/images/home/dapper-iso-logo.svg";

export const HeroBackground = () => {
  return (
    <AspectRatio>
      <Image
        src={DapperIsoLogo}
        alt="Dapper"
        sizes={"(min-width: 768px) 50vw, 632px"}
        className="border-image"
      />
    </AspectRatio>
  );
};
