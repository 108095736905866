import apiClient from "../../../src/lib/api-client/api-client";
import { HomeAdapter, HomeEntity } from "../domain";

const ENDPOINT = "/home";

class HomeApiAdapter implements HomeAdapter {
  async getHome() {
    return await apiClient<HomeEntity>({
      url: ENDPOINT,
      method: "GET",
      baseURL: process.env.NEXT_PUBLIC_APP_URL + "/api/v1",
    });
  }
}

export { HomeApiAdapter };
