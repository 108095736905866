"use client";

import { useScroll, useSpring, useTransform } from "framer-motion";
import { useRef } from "react";

import type { Style } from "architecture/src/shared/domain/types";

import { Stack } from "@/components/ui/layout/stack";
import { useTypographyWithScroll } from "@/components/ui/data-display/typography";

type ScrollyTellingSection = {
  // eslint-disable-next-line no-unused-vars
  children: (ctx: {
    style?: Style;
    refTextContent: React.RefObject<HTMLDivElement>;
  }) => React.ReactNode;
};

const useScrollyTellingTextContent = () => {
  const target = useRef<HTMLDivElement>(null);
  // const { isDevice: isTablet } = useMediaQueryStore();

  // Image container animation
  const { style } = useTypographyWithScroll({
    target,
    offset: ["start end", "start start"],
  });

  // Image animation
  const { scrollYProgress } = useScroll({
    target,
    offset: ["start center", "start start"],
  });

  const spring = useSpring(scrollYProgress);
  const opacity = useTransform(spring, [0, 1], [0, 1]);
  const scale = useTransform(spring, [0, 1], [1.25, 1]);

  return {
    target,
    style: { opacity, scale, ...style },
  };
};

export const ScrollyTellingTextContent: React.FC<ScrollyTellingSection> = ({
  children,
}) => {
  const { target, style } = useScrollyTellingTextContent();

  return (
    <div ref={target}>
      <Stack direction="row" align="center" className="md:min-h-screen">
        {/* <Stack className="md:grid md:grid-cols-2" spacing="lg"> */}
        {children({ style, refTextContent: target })}
        {/* </Stack> */}
      </Stack>
    </div>
  );
};
