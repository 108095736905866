import { HomeAdapter, HomeRepository } from "../domain";

class HomeUseCase implements HomeRepository {
  private homeAdapter: HomeAdapter;

  constructor(_homeAdapter: HomeAdapter) {
    this.homeAdapter = _homeAdapter;
  }

  getHome = () => {
    return this.homeAdapter.getHome();
  };
}

export { HomeUseCase };
