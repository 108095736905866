import React from "react";

import { Badge } from "@/components/ui/data-display/badge/badge";
import { Stack } from "@/components/ui/layout/stack";
import { cn } from "@/src/lib/utils";

interface AvatarListProps extends React.HTMLAttributes<HTMLDivElement> {}

export const AvatarList: React.FC<AvatarListProps> = ({
  children,
  className,
  ...props
}) => {
  const length = React.Children.count(children);

  return (
    <Stack
      //   direction="row"
      justify="center"
      align="center"
      className={cn("flex-row-reverse", className)}
      {...props}
    >
      {length > 1 && (
        <Badge className="z-10 shadow-lg text-primary" variant="outline">
          + 5 más
        </Badge>
      )}
      {children}
    </Stack>
  );
};
