export const keywords = [
  {
    text: "Radicados",
    opacity: "1",
    weight: "500",
    top: "28%",
    left: "3.8%",
    size: "var(--size-keywordsMd)",
    rotate: "0",
    color: "#8F6CF8",
  },
  {
    text: "Bebidas azucaradas",
    opacity: "1",
    weight: "400",
    top: "39%",
    left: "0px",
    size: "var(--size-keywordsBase)",
    rotate: "0",
    color: "#8F6CF8",
  },
  {
    text: "Empleo",
    opacity: "1",
    weight: "500",
    top: "48%",
    left: "4%",
    size: "var(--size-keywordsBase)",
    rotate: "0",
    color: "#C7B6FC",
  },
  {
    text: "Seguridad",
    opacity: "1",
    weight: "400",
    top: "67%",
    left: "7%",
    size: "var(--size-keywordsBase)",
    rotate: "270deg",
    color: "#8F6CF8",
  },
  {
    text: "Salud",
    opacity: "1",
    weight: "800",
    top: "56%",
    left: "13%",
    size: "var(--size-keywordsLg)",
    rotate: "270deg",
    color: "#5723F5",
  },
  {
    text: "Infraestructura",
    opacity: "1",
    weight: "500",
    top: "67%",
    left: "15%",
    size: "var(--size-keywordsBase)",
    rotate: "270deg",
    color: "#C7B6FC",
  },
  {
    text: "POT",
    opacity: "1",
    weight: "700",
    top: "19%",
    left: "26%",
    size: "var(--size-keywordsLg)",
    rotate: "270deg",
    color: "#5723F5",
  },
  {
    text: "Hidrocarburos",
    opacity: "1",
    weight: "500",
    top: "46%",
    left: "23%",
    size: "var(--size-keywordsMd)",
    rotate: "270deg",
    color: "#8F6CF8",
  },
  {
    text: "Transporte",
    opacity: "1",
    weight: "500",
    top: "83%",
    left: "30%",
    size: "var(--size-keywordsMd)",
    rotate: "0deg",
    color: "#8F6CF8",
  },
  {
    text: "Energía",
    opacity: "1",
    weight: "500",
    top: "65%",
    left: "41%",
    size: "var(--size-keywordsBase)",
    rotate: "0deg",
    color: "#C7B6FC",
  },
  {
    text: "IPC",
    opacity: "1",
    weight: "800",
    top: "50%",
    left: "41.6%",
    size: "var(--size-keywordsLg)",
    rotate: "0deg",
    color: "#5723F5",
  },
  {
    text: "Construcción",
    opacity: "1",
    weight: "500",
    top: "21%",
    left: "33%",
    size: "var(--size-keywordsBase)",
    rotate: "270deg",
    color: "#8F6CF8",
  },
  {
    text: "Tecnología",
    opacity: "1",
    weight: "500",
    top: "19%",
    left: "49%",
    size: "var(--size-keywordsMd)",
    rotate: "0",
    color: "#8F6CF8",
  },
  {
    text: "Decretos",
    opacity: "1",
    weight: "800",
    top: "32%",
    left: "46.5%",
    size: "var(--size-keywordsLg)",
    rotate: "0",
    color: "#5723F5",
  },
  {
    text: "Comercio",
    opacity: "1",
    weight: "500",
    top: "45%",
    left: "45.5%",
    size: "var(--size-keywordsBase)",
    rotate: "0",
    color: "#C7B6FC",
  },
  {
    text: "Contratación",
    opacity: "1",
    weight: "500",
    top: "75%",
    left: "44.7%",
    size: "var(--size-keywordsBase)",
    rotate: "270deg",
    color: "#8F6CF8",
  },
  {
    text: "PIB",
    opacity: "1",
    weight: "800",
    top: "45%",
    left: "62.5%",
    size: "var(--size-keywordsLg)",
    rotate: "0deg",
    color: "#5723F5",
  },
  {
    text: "Comunicaciones",
    opacity: "1",
    weight: "400",
    top: "58%",
    left: "60%",
    size: "var(--size-keywordsBase)",
    rotate: "0deg",
    color: "#8F6CF8",
  },
  {
    text: "Descuento al consumidor",
    opacity: "1",
    weight: "400",
    top: "68%",
    left: "62%",
    size: "var(--size-keywordsBase)",
    rotate: "0deg",
    color: "#C7B6FC",
  },
  {
    text: "Resoluciones",
    opacity: "1",
    weight: "500",
    top: "23%",
    left: "63%",
    size: "var(--size-keywordsMd)",
    rotate: "270deg",
    color: "#8F6CF8",
  },
  {
    text: "Inteligencia artificial",
    opacity: "1",
    weight: "500",
    top: "32%",
    left: "71.5%",
    size: "var(--size-keywordsBase)",
    rotate: "270deg",
    color: "#8F6CF8",
  },
];
