"use client";

import { useLayoutEffect, useRef } from "react";
import { motion as m } from "framer-motion";

import { Stack } from "@/components/ui/layout/stack";

import {
  ScrollyTellingDescription,
  ScrollyTellingImage,
  ScrollyTellingTextContent,
  ScrollyTellingTitle,
} from "./components";
import { useScrollyTellingStore } from "./store";
import { HomeEntity } from "architecture/features/home/domain/entities";
import { useLandingCarrouselGetSuspense as useLandingCarrouselGet } from "architecture/src/shared/api/endpoints/landing/landing";

const useScrollyTellingVertical = () => {
  const { refSection, setRefSection } = useScrollyTellingStore();
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setRefSection(ref);
  }, [ref, setRefSection]);

  return {
    ref,
    refSection,
  };
};

export const ScrollyTellingVertical = ({}) => {
  const { ref, refSection } = useScrollyTellingVertical();
  const { data: CarrouselContent } = useLandingCarrouselGet();

  if (CarrouselContent.length === 0) return null;

  return (
    <m.section
      className="container mx-auto overflow-hidden"
      ref={ref}
      initial="off-section"
      whileInView="in-section"
      viewport={{
        amount: 1 / CarrouselContent.length,
      }}
    >
      {CarrouselContent.map(({ title, summary, image }) => {
        return (
          <div key={title} className="py-10 md:py-0">
            <ScrollyTellingVertical.TextContent>
              {({ style }) => {
                return (
                  <Stack
                    className="w-full md:grid md:grid-cols-12 md:flex-row md:items-center"
                    spacing="md"
                  >
                    <div className="md:col-span-6 md:col-start-6 md:order-last">
                      {/* Header */}
                      <Stack direction="column" spacing="md" component="header">
                        {title ? (
                          <ScrollyTellingVertical.Title style={style}>
                            {title}
                          </ScrollyTellingVertical.Title>
                        ) : null}
                        {summary ? (
                          <ScrollyTellingVertical.Description style={style}>
                            {summary}
                          </ScrollyTellingVertical.Description>
                        ) : null}
                      </Stack>
                    </div>
                    {/* Image */}
                    <div className="col-span-4 -mx-4 md:mx-0 md:col-start-2">
                      {refSection && (
                        <ScrollyTellingVertical.Image
                          style={style}
                          image={image}
                          title={title}
                        />
                      )}
                    </div>
                  </Stack>
                );
              }}
            </ScrollyTellingVertical.TextContent>
          </div>
        );
      })}
    </m.section>
  );
};

ScrollyTellingVertical.Description = ScrollyTellingDescription;
ScrollyTellingVertical.Image = ScrollyTellingImage;
ScrollyTellingVertical.TextContent = ScrollyTellingTextContent;
ScrollyTellingVertical.Title = ScrollyTellingTitle;
