import { TypographyWithScroll } from "@/components/ui/data-display/typography";

interface ScrollyTellingTitleProps
  extends React.ComponentPropsWithoutRef<typeof TypographyWithScroll> {
  children: string;
}

export const ScrollyTellingTitle: React.FC<ScrollyTellingTitleProps> = ({
  children,
  style,
  ...rest
}) => {
  return (
    <TypographyWithScroll
      variant="h3"
      style={{
        y: style?.y,
        rotateX: style?.rotateX,
      }}
      classNameContainer="text-left line-clamp-4"
      {...rest}
    >
      <div
        className="[&>*]:text-primary md:text-5xl"
        dangerouslySetInnerHTML={{ __html: children }}
      />
    </TypographyWithScroll>
  );
};
