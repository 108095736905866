import Image from "next/image";

import { Testimonial } from "architecture/src/shared/domain";

import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/surfaces/card";
import { AspectRatio } from "@/components/ui/surfaces/aspect-ratio";
import { Typography } from "@/components/ui/data-display/typography";
import { Stack } from "@/components/ui/layout/stack";

export const TestimonialCard: React.FC<Testimonial> = ({
  title,
  summary,
  company,
  image,
}) => {
  return (
    <Card className="flex flex-col h-full border-2 border-primary">
      <CardHeader className="items-center">
        {/* Image */}
        <div className="w-12 h-12 rounded-dot md:w-28 md:h-28">
          <AspectRatio>
            <Image src={image ?? ""} alt={title} fill />
          </AspectRatio>
        </div>
      </CardHeader>
      <CardContent className="flex-1 text-center">
        {/* Description */}
        <Typography>{summary}</Typography>
      </CardContent>
      <CardFooter className="justify-end text-right">
        <Stack>
          {/* Name */}
          <Typography variant="caption" weight="bold">
            {title}
          </Typography>
          {/* Job */}
          <Typography variant="caption">{company}</Typography>
        </Stack>
      </CardFooter>
    </Card>
  );
};
