import { useLandingTestimonialsGetSuspense as useLandingTestimonialsGet } from "architecture/src/shared/api/endpoints/landing/landing";

import { Stack } from "@/components/ui/layout/stack";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/navigation/carousel";
import { TestimonialCard } from "./components/testimonial.card";

export const Testimonial: React.FC<{}> = () => {
  const { data: testimonial } = useLandingTestimonialsGet();

  if (testimonial.length === 0) return null;

  return (
    <section className="container py-10 mx-auto px-14 md:max-w-3xl">
      <Stack>
        {testimonial.length === 1 ? (
          <Stack align="center">
            <Stack className="max-w-[320px]">
              <TestimonialCard {...testimonial[0]} />
            </Stack>
          </Stack>
        ) : (
          <Carousel
            opts={{
              align: "start",
              loop: true,
            }}
          >
            <CarouselContent>
              {testimonial.map((item) => (
                <CarouselItem className="md:basis-1/2" key={item.id}>
                  <TestimonialCard {...item} />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselPrevious
              className="disabled:opacity-0"
              variant="default"
            />
            <CarouselNext className="disabled:opacity-0" variant="default" />
          </Carousel>
        )}
      </Stack>
    </section>
  );
};
