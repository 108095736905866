import { useAuthProfileList } from "../../../src/shared/api/endpoints/auth/auth";

import { UserAdapter } from "../domain";

class UserApiAdapter implements UserAdapter {
  getProfile = () => {
    const { data } = useAuthProfileList();

    return data;
  };
}

export { UserApiAdapter };
