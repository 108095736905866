import { ContentSecondary } from "architecture/src/shared/domain";

import { Stack } from "@/components/ui/layout/stack";
import { Typography } from "@/components/ui/data-display/typography";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/ui/data-display/avatar";

export const PublicListeningSpeaker: React.FC<ContentSecondary> = ({
  image,
  title,
  summary2,
}) => {
  return (
    <div className="p-4 pb-10 rounded-max bg-background text-foreground md:p-10 md:rounded-full">
      <Stack spacing="md" align="center" className="md:flex-row">
        <Avatar className=" w-36 h-36 rounded-dot">
          <AvatarImage src={image ?? ""} alt={title ?? ""} />
          <AvatarFallback>{title}</AvatarFallback>
        </Avatar>
        <Typography
          className="text-left text-accent-foreground"
          variant="body2"
        >
          {summary2}
        </Typography>
      </Stack>
    </div>
  );
};
