"use client";

import { motion, useScroll, useTransform } from "framer-motion";
import { useRef } from "react";

import { useLandingCarrouselGetSuspense as useLandingCarrouselGet } from "architecture/src/shared/api/endpoints/landing/landing";

import { Stack } from "@/components/ui/layout/stack";
import {
  ScrollyTellingDescription,
  ScrollyTellingImage,
  ScrollyTellingTextContent,
  ScrollyTellingTitle,
} from "./components";

export const ScrollyTellingHorizontal = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });
  const { data: CarrouselContent } = useLandingCarrouselGet();

  const x = useTransform(
    scrollYProgress,
    [0, 1],
    ["cal(0%-0vw)", "calc(-100% + 100vw)"],
  );

  if (CarrouselContent.length === 0) return null;

  return (
    <section
      ref={targetRef}
      className="relative"
      style={{
        height: `calc(${CarrouselContent.length} * 100vh)`,
      }}
    >
      <div className="sticky top-0 flex items-center h-screen py-16 overflow-hidden">
        <motion.div style={{ x }} className="flex gap-4">
          {CarrouselContent.map(({ title, summary, image }) => {
            return (
              <div className="flex flex-col w-screen px-6" key={title}>
                <Stack spacing="md">
                  <div className="container">
                    <ScrollyTellingHorizontal.Image
                      image={image}
                      title={title}
                    />
                  </div>
                  <Stack
                    spacing="md"
                    component="header"
                    className="container text-left"
                  >
                    {title ? (
                      <ScrollyTellingHorizontal.Title>
                        {title}
                      </ScrollyTellingHorizontal.Title>
                    ) : null}
                    {summary ? (
                      <ScrollyTellingHorizontal.Description>
                        {summary}
                      </ScrollyTellingHorizontal.Description>
                    ) : null}
                  </Stack>
                </Stack>
              </div>
            );
          })}
        </motion.div>
      </div>
    </section>
  );
};

ScrollyTellingHorizontal.TextContent = ScrollyTellingTextContent;
ScrollyTellingHorizontal.Title = ScrollyTellingTitle;
ScrollyTellingHorizontal.Description = ScrollyTellingDescription;
ScrollyTellingHorizontal.Image = ScrollyTellingImage;
