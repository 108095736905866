import { Stack } from "@/components/ui/layout/stack";
import { Typography } from "@/components/ui/data-display/typography";
import { Button } from "@/components/ui/inputs/button";
import Image from "next/image";
import Link from "next/link";
import wgsLogo from "public/static/images/home/wgs-logo.png";

export const WGSMobile: React.FC<{}> = () => {
  return (
    <Stack direction="column" className="relative">
      <Image src={wgsLogo} alt="WGS" className="my-6" />
      <Stack
        spacing="md"
        className="relative grid grid-cols-5 "
        direction="column"
      >
        <Typography variant="h2" className="text-center col-start-1 col-end-6">
          Forging Alliances for Global Impact
        </Typography>
        <Typography
          variant="body1"
          weight="medium"
          className="text-center col-start-2 col-end-5"
        >
          Unvelling LAC-MENA synergies through data insights
        </Typography>
        <Link
          href="https://dapper.short.gy/wgs_pbi_mobile"
          className="px-25 w-full col-start-2 col-span-3"
        >
          <Button
            variant="default"
            className="px-25 w-full col-start-2 col-span-3"
          >
            Check out the report
          </Button>
        </Link>
      </Stack>
    </Stack>
  );
};
