"use client";

import { useScroll, useSpring, useTransform } from "framer-motion";

import Typography from "./typography";
import { withScroll } from "@/components/utils/animations";

export const useTypographyWithScroll = ({
  target,
  offset = ["start end", "start center"],
}: {
  target?: React.RefObject<HTMLDivElement>;
  offset?: any;
}) => {
  const { scrollYProgress } = useScroll({
    target,
    offset,
  });

  const spring = useSpring(scrollYProgress, {
    damping: 20,
    stiffness: 100,
  });

  const y = useTransform(spring, [0, 1], ["100%", "0%"]);
  const rotateX = useTransform(spring, [0, 1], [-24, 0]);

  return {
    target,
    style: {
      y,
      rotateX,
    },
  };
};

export const TypographyWithScroll = withScroll(Typography);
