import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/src/lib/utils";

const cardVariants = cva("", {
  variants: {
    variant: {
      default: "bg-card text-card-foreground",
      secondary: "bg-secondary text-secondary-foreground",
      primary: "bg-primary text-primary-foreground",
      muted: "bg-muted text-muted-foreground",
    },
    shadow: {
      none: "shadow-none",
      xl: "shadow-xl",
    },

    rounded: {
      default: "rounded-2xl",
      max: "rounded-max",
    },
    border: {
      default: "border",
      none: "border-none",
    },
  },
  defaultVariants: {
    variant: "default",
    shadow: "none",
    rounded: "default",
    border: "none",
  },
});

export interface CardProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardVariants> {}

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, variant, shadow, rounded, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(cardVariants({ variant, shadow, rounded }), className)}
      {...props}
    />
  ),
);
Card.displayName = "Card";

// TODO: Create component provider for card container variants
const cardContainerVariants = cva("", {
  variants: {
    size: {
      sm: "p-4",
      default: "p-6 md:p-10",
      xl: "p-8",
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export interface CardHeaderProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardContainerVariants> {}

const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ className, size, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        cardContainerVariants({ size }),
        "flex flex-col space-y-2 md:space-y-4",
        className,
      )}
      {...props}
    />
  ),
);
CardHeader.displayName = "CardHeader";

const CardTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => (
  <h3
    ref={ref}
    className={cn(
      "text-2xl font-semibold leading-none tracking-tight",
      className,
    )}
    {...props}
  />
));
CardTitle.displayName = "CardTitle";

const CardDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <p ref={ref} className={cn("text-sm", className)} {...props} />
));
CardDescription.displayName = "CardDescription";

export interface CardContentProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardContainerVariants> {}

const CardContent = React.forwardRef<HTMLDivElement, CardContentProps>(
  ({ className, size, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(cardContainerVariants({ size }), "!pt-0", className)}
      {...props}
    />
  ),
);
CardContent.displayName = "CardContent";

export interface CardFooterProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof cardContainerVariants> {}

const CardFooter = React.forwardRef<HTMLDivElement, CardFooterProps>(
  ({ className, size, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        cardContainerVariants({ size }),
        "flex items-center !pt-0",
        className,
      )}
      {...props}
    />
  ),
);
CardFooter.displayName = "CardFooter";

export {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
};
