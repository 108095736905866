import { cn } from "@/src/lib/utils";

function Skeleton({ className }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <span
      aria-live="polite"
      aria-busy="true"
      className={cn(className, "inline-block")}
    >
      <span className="inline-flex w-full leading-none bg-gray-300 rounded-md select-none animate-pulse">
        ‌
      </span>
      <br />
    </span>
  );
}

const SVGSkeleton = ({ className }: React.HTMLAttributes<SVGElement>) => (
  <svg className={cn("animate-pulse rounded bg-gray-300", className)} />
);

function GeneralSkeleton() {
  return (
    <div className="flex flex-col items-stretch gap-2 md:gap-3 p-6 w-full bg-background rounded-2xl">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center gap-2 md:gap-3">
          <div className="w-2 h-2"></div>
          <h2>
            <Skeleton className="w-[160px] max-w-full" />
          </h2>
        </div>
        <a className="h-10 px-3 py-2 items-center justify-center transition-colors hidden md:flex">
          <Skeleton className="w-[72px] max-w-full" />
        </a>
      </div>
      {Array.from({ length: 5 }).map((_, index) => {
        return (
          <div
            className="flex flex-col items-stretch gap-3 md:gap-4"
            key={index}
          >
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-col items-stretch">
                <span>
                  <Skeleton className="w-[136px] max-w-full" />
                </span>
                <h6 className="group-hover:underline line-clamp-2">
                  <Skeleton className="w-[100px] md:w-[300px] max-w-full" />
                </h6>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export { SVGSkeleton, Skeleton, GeneralSkeleton };
