import { UserAdapter, UserRepository } from "../domain";

class UserUseCase implements UserRepository {
  private userAdapter: UserAdapter;

  constructor(_userAdapter: UserAdapter) {
    this.userAdapter = _userAdapter;
  }

  getProfile = () => {
    return this.userAdapter.getProfile();
  };
}

export { UserUseCase };
