import { Stack } from "@/components/ui/layout/stack";
import { Typography } from "@/components/ui/data-display/typography";
import { Button } from "@/components/ui/inputs/button";
import Image from "next/image";
import Link from "next/link";
import wgsLogo from "public/static/images/home/wgs-logo.png";

export const WGSDesktop: React.FC<{}> = () => {
  return (
    <Stack
      spacing="md"
      className="container relative grid grid-cols-4 grid-rows-6 pt-8 -md:min-h-screen md:items-center md:gap-x-10 gap-y-0 md:grid-cols-12 min-h-[594px] max-h-[594px] relative w-full h-screen bg-cover bg-center z-1"
      direction="row"
    >
      <Stack
        spacing="md"
        direction="column"
        className="col-start-1 col-end-6 row-start-2 "
      >
        <Image src={wgsLogo} alt="WGS" className="max-w-[187px]" />
        <Typography variant="h2" className="">
          Forging Alliances for Global Impact
        </Typography>
      </Stack>
      <Stack
        spacing="md"
        direction="column"
        align="center"
        className="col-start-6 col-end-12 row-start-4 flex justify-center"
      >
        <Typography variant="h4" className="max-w-[416px] text-center ">
          Unveiling LAC-MENA synergies through data insights
        </Typography>
        <Link href="https://dapper.short.gy/wgs-pbi-desktop">
          <Button variant="default">Check out the report</Button>
        </Link>
      </Stack>
    </Stack>
  );
};
